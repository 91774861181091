/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';

import Link from 'next/link';

import { Button, ECImage, Icon, Section } from 'components/ui';
import { ImageSetKeys, useImages } from 'context/ImagesProvider';
import { useTracking } from 'context/TrackingProvider';
import { useTranslations } from 'services/translations/TranslationsProvider';

interface Props {
  ctaId?: string;
  range?: 'dog' | 'cat' | 'puppy' | 'kitten';
}

const ProductQuizActivation2 = ({ ctaId, range }: Props): JSX.Element => {
  const { img } = useImages(ImageSetKeys.ContentProductQuizActivation);
  const { t } = useTranslations('content.productQuizActivation');
  const {
    events: { trackCtaClicked },
  } = useTracking();

  let image = 'mobileResult';
  if (range === 'dog') {
    image = 'mobileResultDog';
  }
  if (range === 'cat') {
    image = 'mobileResultCat';
  }
  if (range === 'puppy') {
    image = 'mobileResultPuppy';
  }
  if (range === 'kitten') {
    image = 'mobileResultKitten';
  }

  return (
    <Section color="purple-550">
      <div className="overflow-hidden">
        <div className="container container--lg mx-auto grid sm:grid-cols-2 grid-cols-1 items-center sm:gap-8">
          <div className="flex flex-col items-start">
            <h3
              className="text-3xl md:text-3.5xl leading-none font-rodetta
              mx-auto text-white mb-8 text-center sm:text-left w-full maxXs:flex maxXs:justify-center"
            >
              {t('title', {
                meal: `${t('titleMeal')}`,
              })}
            </h3>
            <div className="sm:flex flex-col items-start hidden">
              <Link href="/quiz/" passHref legacyBehavior>
                <Button
                  color="purple"
                  size="large"
                  onClick={() => ctaId && trackCtaClicked({ ctaId })}
                >
                  {t('ctaLabel')}
                </Button>
              </Link>
              <div className="text-white font-rooney text-sm flex justify-center mt-4">
                <Icon name="clock" className="w-4" />
                <p className="pl-1 font-medium">{t('durationIndication')}</p>
              </div>
            </div>
          </div>

          <div>
            <ECImage
              className="mx-auto maxMd:max-w-[80%] aspect-1 object-contain"
              pictureClassName="block relative pointer-events-none"
              img={img(image)}
              srcOptions={{ w: 1600, q: 85 }}
            />
            <div className="flex flex-col items-center sm:hidden mt-8">
              <Link href="/quiz/" passHref legacyBehavior>
                <Button
                  color="purple"
                  size="large"
                  onClick={() => ctaId && trackCtaClicked({ ctaId })}
                >
                  {t('ctaLabel')}
                </Button>
              </Link>
              <div className="text-white font-rooney text-sm flex justify-center mt-4">
                <Icon name="clock-mini" className="w-4" />
                <p className="pl-2 font-medium">{t('durationIndication')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ProductQuizActivation2;
