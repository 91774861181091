import React, { useState } from 'react';

import { Icon, Section } from 'components/ui';
import { useTranslations } from 'services/translations/TranslationsProvider';

const Footnote2: React.FC = () => {
  const { t } = useTranslations('content.footnote2');
  const [showReferences, setShowReferences] = useState(false);

  const toggleReferences = () => {
    setShowReferences(!showReferences);
  };

  return (
    <Section color="white" size="small" id="footnote">
      <div className="container container--sm font-rooney text-gray-500 text-sm">
        <h3 className="font-bold text-gray-500 mb-4 text-xl leading-7">{t('footnote.title')}</h3>
        <p className="mb-4">{t('footnote.description')}</p>
        <button className="group flex items-center" type="button" onClick={toggleReferences}>
          <h4 className="font-bold text-gray-700 text-sm leading-[1.125rem] group-hover:text-purple-400 transition">
            {showReferences ? t('footnote.hideReferences') : t(`footnote.showReferences`)}
          </h4>
          <Icon
            name="chevron-down-narrow"
            className={`text-purple-700 w-4 h-4 ml-1 group-hover:text-purple-400 transition transform ${
              showReferences ? 'rotate-180' : ''
            }`}
          />
        </button>
        <ul
          className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-6"
          style={{ display: showReferences ? 'grid' : 'none' }}
        >
          <li>
            <ul className="flex flex-col gap-2">
              {Array.from(Array(5).keys()).map((index) => {
                const referenceNumber = index + 1;
                return (
                  <li
                    key={referenceNumber}
                    className="border border-blue-200 rounded-lg p-2 min-h-[3.25rem] group"
                  >
                    <a
                      href={t(`footnote.card${referenceNumber}.href`)}
                      className="relative block"
                      target="_blank"
                    >
                      <p className="pr-6">
                        ({referenceNumber}) {t(`footnote.card${referenceNumber}.description`)}
                      </p>
                      <div className="group-hover:bg-purple-700 rounded-full h-4 w-4 absolute top-0 right-0 transform transition transition-color flex items-center justify-center">
                        <Icon
                          name="arrow-new"
                          className="text-purple-700 h-3 w-3 transform shrink-0 group-hover:text-white transition transition-color"
                        />
                      </div>
                    </a>
                  </li>
                );
              })}
            </ul>
          </li>

          <li>
            <ul className="flex flex-col gap-2">
              {Array.from(Array(5).keys()).map((index) => {
                const referenceNumber = index + 6;
                return (
                  <li
                    key={referenceNumber}
                    className="border border-blue-200 rounded-lg p-2 min-h-[3.25rem] group"
                  >
                    <a
                      href={t(`footnote.card${referenceNumber}.href`)}
                      className="relative block"
                      target="_blank"
                    >
                      <p className="pr-6">
                        ({referenceNumber}) {t(`footnote.card${referenceNumber}.description`)}
                      </p>
                      <div className="group-hover:bg-purple-700 rounded-full h-4 w-4 absolute top-0 right-0 transform transition transition-color flex items-center justify-center">
                        <Icon
                          name="arrow-new"
                          className="text-purple-700 h-3 w-3 transform shrink-0 group-hover:text-white transition transition-color"
                        />
                      </div>
                    </a>
                  </li>
                );
              })}
            </ul>
          </li>
        </ul>
      </div>
    </Section>
  );
};

export default Footnote2;
