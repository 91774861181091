import { FC, PropsWithChildren } from 'react';

import type { NextPage } from 'next';


// Types --------------
interface CreateNextPageConfig {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Layout?: FC<PropsWithChildren<any>>;
  layoutClassName?: string;
  pageBannerOptions?: { hide?: boolean, textOverride?: { text?: string; translationKey?: string }}
}

// Utils ---------------
const DefaultWrapper: FC<PropsWithChildren<Record<string, never>>> = ({children}) => (
  <>
    {children}
  </>
)


/** A util to link (layout) components and meta data to a given `PageComponent` so they can be used in `_app.tsx`. 
 *
 * This functions sets the given `options` as properties on the `PageComponent`.
 * If an option like `options.Layout` asks for an optional `FC` an none is given, it defaults to `React.Fragment`.
 * 
 *
 * @example ```typescriptreact
 * // How we used to work
 * const Home: NextPage<Props> = (props) => (
 *   // This layout rerenders on every page navigation
 *   <Layout>
 *     <h1>Hi</h1>
 *   </Layout>
 * );
 * export default Home;
 *
 * // How this function works
 * /// TIP: if you default export this without a name, the import namespace doesn't get cluttered with one time use page components.
 * export default createNextPage<Props>((props) => <h1>Hi</h1>, {
 *   // This gets rendered in _app.tsx and will only rerender when another layout is given (e.g. LoginLayout)
 *   Layout,
 * });
 * ``` */
// prettier-ignore
// eslint-disable-next-line prettier/prettier
const createNextPage = <Props = Record<string, never>>(
  PageComponent: NextPage<Props>, 
  config: CreateNextPageConfig
): NextPage<Props> & Required<CreateNextPageConfig> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const Page: ReturnType<typeof createNextPage<any>> = PageComponent;

  // Set all of the options
  Page.Layout = config.Layout ?? DefaultWrapper;

  Page.layoutClassName = config.layoutClassName ?? '';
  Page.pageBannerOptions = config.pageBannerOptions ?? { hide: false, textOverride: null };

  return Page;
};

export default createNextPage;

